class LuCoreConfig {

    is_production = process.env.REACT_APP_LC_LUCORE_API_URL ? process.env.REACT_APP_LC_LUCORE_API_URL.includes("localhost") ? false : true : false;
    is_sandbox = process.env.REACT_APP_LC_LUCORE_API_URL ? process.env.REACT_APP_LC_LUCORE_API_URL.includes("sandbox") ? true : false : false;
    endpoint_url = process.env.REACT_APP_LC_LUCORE_API_URL;
    pull_url = process.env.REACT_APP_LC_LUCORE_PULL_URL;
    track_url = process.env.REACT_APP_LC_LUCORE_TRACK_URL;
    kibana_url = process.env.REACT_APP_LC_KIBANA_URL;
    app_token = process.env.REACT_APP_LC_LUCORE_APP_TOKEN;
    google_maps_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    endpoint_version = 2;
}

export const LuCoreConstants = {    
    application_status : {
        LC_APPLICATION_STATUS_DRAFT : 0,
        LC_APPLICATION_STATUS_ACTIVE : 1,
        LC_APPLICATION_STATUS_INACTIVE : 2,
        LC_APPLICATION_STATUS_DELETED : 3,
        LC_APPLICATION_STATUS_PENDING_APPROVAL : 4,
        LC_APPLICATION_STATUS_REJECTED : 5
    },
    operator_status : {
        LC_OPERATOR_STATUS_UNKOWN : 0,
        LC_OPERATOR_STATUS_CURRENT : 1,
        LC_OPERATOR_STATUS_NEVER_CONTACTED : 2,
        LC_OPERATOR_STATUS_NO_RESPONSE : 3,
        LC_OPERATOR_STATUS_INACTIVE : 4,
        LC_OPERATOR_STATUS_OUT_OF_BUSINESS : 5,
        LC_OPERATOR_STATUS_ACQUIRED_BY_ANOTHER_OPERATOR : 6,
        LC_OPERATOR_STATUS_WAITING_FOR_RATES_AND_BOARDS : 7,
        LC_OPERATOR_STATUS_READY : 8,
        LC_OPERATOR_STATUS_REVISIT : 9,
        LC_OPERATOR_STATUS_NO_INTEREST : 10,
        LC_OPERATOR_STATUS_DEMO : 11
    },
    operator_contract_status : {
        LC_OPERATOR_CONTRACT_STATUS_NONE : 0,
        LC_OPERATOR_CONTRACT_STATUS_SENT : 1,
        LC_OPERATOR_CONTRACT_STATUS_SIGNED : 2
    },
    operator_digital_board_state : {
        LC_OPERATOR_DIGITAL_BOARD_STATE_UNKOWN : 0,
        LC_OPERATOR_DIGITAL_BOARD_STATE_NONE : 1,
        LC_OPERATOR_DIGITAL_BOARD_STATE_HAS_DIGITALS : 2,
        LC_OPERATOR_DIGITAL_BOARD_STATE_DIGITALS_PLANNED : 3
    },
    campaign_state : {
        LC_CAMPAIGN_STATE_PENDING : 0,
        LC_CAMPAIGN_STATE_PULLING : 1,
        LC_CAMPAIGN_STATE_PULLING_PAUSED : 2,
        LC_CAMPAIGN_STATE_RUNNING : 3,
        LC_CAMPAIGN_STATE_PAUSED : 4,
        LC_CAMPAIGN_STATE_COMPLETE : 5,
        LC_CAMPAIGN_STATE_DRAFT : 6,
        LC_CAMPAIGN_STATE_PENDING_TRAFFIC : 7,
        LC_CAMPAIGN_STATE_REJECTED : 8,


    },
    comments : {
        LC_COMMENT_CLASS_COMMENT : 1,
        LC_COMMENT_CLASS_LOG : 2,
        LC_COMMENT_CLASS_MENTION : 3,
        LC_COMMENT_CLASS_EVENT : 4,
        LC_COMMENT_CLASS_EVENT_FAIL : 5,
    },
    digital_boards : {
        LC_DIGITAL_BOARD_STATUS_ACTIVE : 1,
        LC_DIGITAL_BOARD_STATUS_INACTIVE : 0,
        LC_DIGITAL_BOARD_IMP_CALC_METHOD_NONE : 0,
        LC_DIGITAL_BOARD_IMP_CALC_METHOD_AVG_DAILY : 1
    },
    inventory_feeds: {
        LC_INVENTORY_FEED_STATUS_DELETED: 0,
        LC_INVENTORY_FEED_STATUS_SETUP: 1,
        LC_INVENTORY_FEED_STATUS_READY: 2,
        LC_INVENTORY_FEED_STATUS_RUNNING: 3,
        LC_INVENTORY_FEED_STATUS_ERROR: 4,
        LC_INVENTORY_FEED_STATUS_RUNNABLE: 5,
        LC_INVENTORY_FEED_STATUS_RETRY: 6,
        LC_INVENTORY_FEED_STATUS_QUEUED: 7
    },
    inventory_exports: {
        LC_INVENTORY_EXPORT_STATUS_DELETED: 0,
        LC_INVENTORY_EXPORT_STATUS_SETUP: 1,
        LC_INVENTORY_EXPORT_STATUS_READY: 2,
        LC_INVENTORY_EXPORT_STATUS_RUNNING: 3,
        LC_INVENTORY_EXPORT_STATUS_ERROR: 4,
        LC_INVENTORY_EXPORT_STATUS_RUNNABLE: 5,
        LC_INVENTORY_EXPORT_STATUS_RETRY: 6,
        LC_INVENTORY_EXPORT_STATUS_QUEUED: 7,
        LC_INVENTORY_EXPORT_STATUS_EMPTY: 8
    },
    inventory_items : {
        LC_INVENTORY_ITEM_MAX_TITLE_LENGTH : 120
    },
    inventory_item_creative_state: {
        LC_INVENTORY_ITEM_CREATIVE_STATE_NONE: 0,
        LC_INVENTORY_ITEM_CREATIVE_STATE_BUILDING: 1,
        LC_INVENTORY_ITEM_CREATIVE_STATE_COMPLETE: 2,
        LC_INVENTORY_ITEM_CREATIVE_STATE_ERROR: 3
    },
    inventory_item_status: {
        LC_INVENTORY_ITEM_STATUS_ACTIVE: 1,
        LC_INVENTORY_ITEM_STATUS_SOLD: 0,
        LC_INVENTORY_ITEM_STATUS_DELETED: -1,
        LC_INVENTORY_ITEM_STATUS_DRAFT: 2
    },
    inventory_photos: {
        LC_PHOTO_CROP_DEFAULT_ASPECT_RATIO : 1.333
    },
    hovers: {
        LC_HOVER_STATUS_DELETED: 0,
        LC_HOVER_STATUS_SETUP: 1,
        LC_HOVER_STATUS_READY: 2,
        LC_HOVER_STATUS_STARTING: 9,
        LC_HOVER_STATUS_FETCHING: 3,
        LC_HOVER_STATUS_FETCH_COMPLETE: 4,
        LC_HOVER_STATUS_GATHERING_FACTS: 5,
        LC_HOVER_STATUS_GATHERING_FACTS_COMPLETE: 6,
        LC_HOVER_STATUS_ERROR: 7,
        LC_HOVER_STATUS_RUNNABLE: 8,
    },
    mass_emails : {
        LC_MASS_EMAIL_STATUS_COMPOSING : 2,
        LC_MASS_EMAIL_STATUS_READY : 3,
        LC_MASS_EMAIL_STATUS_SENDING : 4,
        LC_MASS_EMAIL_STATUS_COMPLETE : 5,
        LC_MASS_EMAIL_STATUS_ERROR : 6,
        LC_MASS_EMAIL_STATUS_DELETED : 7
    }
}

export const LID = {
    lid: {
        uri: '/#/',
        types: [
            { type: 'account', prefix: 'LA', class: 'App\\Account', icon : 'fa fa-institution' },
            { type: 'application', prefix: 'LAP', class: 'App\\Application', icon : 'fa fa-snowflake-o' },
            { type: 'agency', prefix: 'LY', class: 'App\\Agency', icon: 'fa fa-building-o' },
            { type: 'category', prefix: 'LC', class: 'App\\Category', icon: 'fa fa-folder-o' },
            { type: 'digital-board', prefix: 'LB', class: 'App\\DigitalBoard', icon: 'fa fa-television' },
            { type: 'hover', prefix: 'LH', class: 'App\\Hover' },
            { type: 'hover-run', prefix: 'LHR', class: 'App\\HoverRun' },
            { type: 'hover-test', prefix: 'LHT', class: 'App\\HoverTest' },
            { type: 'hover-test-run', prefix: 'LHTR', class: 'App\\HoverTestRun' },
            { type: 'user', prefix: 'LU', class: 'App\\User', icon: 'fa fa-user-o' },
            { type: 'inventory-feed', prefix: 'LF', class: 'App\\InventoryFeed', icon: 'fa fa-feed' },
            { type: 'inventory-feed-run', prefix: 'LFR', class: 'App\\InventoryFeedRun', icon: 'fa fa-feed' },
            { type: 'inventory-feed-provider', prefix: 'LFP', class: 'App\\InventoryFeedProvider', icon: 'fa fa-space-shuttle' },
            { type: 'inventory-export', prefix: 'LE', class: 'App\\InventoryExport', icon: 'fa fa-globe' },
            { type: 'inventory-export-run', prefix: 'LER', class: 'App\\InventoryExportRun', icon: 'fa fa-globe' },
            { type: 'inventory-item', prefix: 'LI', class: 'App\\InventoryItem', icon: 'fa fa-database' },
            { type: 'location', prefix: 'LL', class: 'App\\Location', icon: 'fa fa-map-pin' },
            { type: 'mass-email', prefix: 'LME', class: 'App\\MassEmail', url: '/mass-emails' },
            { type: 'image', prefix: 'LM', class: 'App\\Image', icon: 'fa fa-file-image-o' },
            { type: 'task', prefix: 'LT', class: 'App\\Task', icon: 'fa fa-tasks'},

            { type: 'report', prefix: 'LR', class: 'App\\LACReport', icon: 'fa fa-table'},

            { type: 'invoice', prefix: 'LV', class: 'App\\Invoice', icon: 'fa fa-file-text-o' },
            { type: 'invoice-payment', prefix: 'LVP', class: 'App\\InvoicePayment', icon: 'fa fa-file-text-o' },
            { type: 'billing-plan', prefix: 'LBP', class: 'App\\BillingPlan', icon: 'fa fa-file-text-o' },

            { type: 'digital-board-to-image', prefix: 'LBTI', class: 'App\\DigitalBoardToImage', icon: 'fa fa-file-image-o'},
            { type: 'inventory-photo', prefix: 'LP', class: 'App\\InventoryPhoto', icon: 'fa fa-file-image-o' },
            { type: 'creatives-to-board-image', prefix: 'LCBI', class: 'App\\CreativesToBoardImage', icon: 'fa fa-file-image-o' }
            
        ]
    },
    phab: {
        uri: 'https://lch.lcdevops.com/',
        types: [
            { type: 'task', prefix: 'T' },
            { type: 'revision', prefix: 'D' },
            { type: 'passphrase', prefix: 'K' },
            { type: 'phame', prefix: 'J' }
        ]
    }
}


export const getLidObjectForType = (objectType) => {
    return LID.lid.types.find( item => item.type === objectType  )
}


export const LuCoreServiceSettings = {
    axios: {
        requestTimeoutMs: 50000,
        maxRequestsPerSecond: 5,
    },
    cache: {
        defaultStoreTimeoutMs: 6000,
        cachePendingRaceDelayMs: 100, //Base number of ms to delay a cachePending check call (Bs)
        cachePendingRaceDelayFuzzMs: 25, //+/- seconds for the randomizer Fs (e.g.  The actual seconds will be between (Bs-Fs and Bs_Fs) )
        cachePendingMaxChecks: 100   //Max number of times we will check if the cache has arrived from a pending call
    }
}

export default LuCoreConfig;
